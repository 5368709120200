import { useState } from "react";
import { useNavigate } from "react-router-dom";
import STORAGEKEY from "../config/APP/app.config";
import { ApiPost } from "../helper/API/ApiData";
import AuthStorage from "../helper/AuthStorage";
import Register from "./register";

import { useDispatch } from 'react-redux'
import { Login_User } from '../redux/reducers/authReducer'
import bcrypt from "bcryptjs-react";
import {encrypt} from "n-krypta";
import { toast } from 'react-toastify';
import CryptoJS from "crypto-js";





function Login() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const loginForm = {
        email: "",
        password: "",
        email_error: "",
        password_error: ""
    };

    const [loginData, setLoginData] = useState<any>(loginForm);
    const [signupModal, setSignupModal] = useState(false);
    const [hide, setHide] = useState<any>(true);


    const handleChange = (e: any) => {
        if (e.target.name === "agreeTerms") {
            setLoginData({ ...loginData, [e.target.name]: e.target.checked })
        }
        setLoginData({
            ...loginData,
            [e.target.name]: e.target.value
        })
    }

    const  signin = async () => {
        let error = { ...loginData };
        let emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/;
        // let passwordPatten = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

        if (!loginData.email) {
            error = { ...error, email_error: "Please enter your email" };
        }
        if (loginData.email && !emailPattern.test(loginData.email)) {
            error = { ...error, email_error: "Please enter a valid email" };
        }
        if (!loginData.password) {
            error = { ...error, password_error: "Please enter your password" };
        }
        // if (loginData.password && !passwordPatten.test(loginData.password)) {
        //     error = { ...error, password_error: "At least 8 characters, with at least 1 uppercase, 1 lowercase and 1 number" };
        // }        
       
        // const secretKey="key";
        // const encryptstring=encrypt(loginData.password,secretKey)
        
      
        // loginData.password=encryptstring;
        
        setLoginData(error)

        const secretPass = "XkhZG4fW2t2W";

        if (error.email_error == null &&
            error.password_error == null) {
            
            //changes-related-to-password-encrypt 
            const data = CryptoJS.AES.encrypt(
                    JSON.stringify(loginData.password),
                    secretPass
                  ).toString();
            const new_data={...loginData,password:data}                  
           
            const { email_error,password_error, ...payload } = new_data;
            ApiPost("auth/login", payload).then((res: any) => {
                setLoginData(loginForm);
                // dispatch(changeLoginState(true));
                if (res?.data?.user?.first_time_login) {
                    navigate(res?.data?.link ?? "/login", { replace: true });
                } else {
                    AuthStorage.setStorageData(
                        STORAGEKEY.token,
                        res.data.token,
                        true
                        //   stayLogedIn
                    );
                    AuthStorage.setStorageJsonData(
                        STORAGEKEY.userData,
                        res.data,
                        true
                        //   stayLogedIn
                    );
                    dispatch(Login_User(res.data))

                    navigate("/", { replace: true });
                }
            }).catch((err) => {
                let error = loginData;
                // toast.error(err?.error , {
                //     position: "top-right",
                //     theme: "colored"
                //   })
                if (err?.error == "User Not Found") {
                    error = { ...error, email_error: "User Not Found" };
                }
                if (err?.error == "Password Wrong") {
                    error = { ...error, password_error: "Password Wrong" };
                }
                setLoginData({ ...loginData, ...error })
            })
        }
    }

    const onHideSignup = () => {
        setSignupModal(true)
    }

    const closeSignup = () => {
        setSignupModal(false)
    }

    const signup = () => {
        navigate("/register", { replace: true });
    }

    const forgotPass = () => {
        navigate("/forgotpassword", { replace: true });

    }
    return (
        <>
            <section className="auth-page-section">

                {/* START::DIALOG */}
                <div className="popup-box max-w-[500px]">

                    {/* START::HEADER */}
                    <div className="flex items-center justify-between">
                        <h1 className="text-[22px] font-semibold ">Sign in</h1>
                    </div>
                    {/* END::HEADER */}

                    {/* START::FORM */}
                    <div className="space-y-[22px]">
                        <div className="space-y-1">
                            <label htmlFor="email" className={`input-label ${loginData.email_error ? "text-red-500" : ""}`}>Email<span style={{color:'red'}}>*</span></label>
                            <div id="email" className={`input-wrap ${loginData.email_error ? "border-red-500" : ""}`}>
                                <img src="assets/email.svg" alt="" />
                                <input type="text" className="w-full text-sm" name="email" value={loginData.email} onChange={(e: any) => {
                                    setLoginData({ ...loginData, email: e.target.value, email_error: null })
                                }} placeholder="johndue@xyz.com" />
                            </div>
                            <p className="text-red-500 text-sm">{loginData.email_error}</p>
                        </div>
                        <div className="space-y-1">
                            <label htmlFor="password" className={`input-label ${loginData.password_error ? "text-red-500" : ""}`}>Password<span style={{color:'red'}}>*</span></label>
                            <div id="password" className={`input-wrap ${loginData.password_error ? "border-red-500" : ""}`}>
                                <img src="assets/lock.svg" alt="" />
                                <input type={hide ? "password" : "text"} className="w-full text-sm" placeholder="XXXXXXXXXX" name="password" value={loginData.password} onChange={(e: any) => {
                                    setLoginData({ ...loginData, password: e.target.value, password_error: null })
                                }} />
                                <img src="assets/eyes.svg" onClick={() => setHide(!hide)} alt="" />
                            </div>
                            <p className='text-red-500 text-sm'>{ loginData.password_error}</p>
                        </div>
                        <div className="flex items-center justify-between text-sm">
                            <div className="flex items-center space-x-[10px]">
                                <input type="checkbox" id="remember" name="agreeTerms"
                                    onChange={(e) => {
                                        handleChange(e);
                                    }} className="" />
                                <label htmlFor="remember" className="">Remember me</label>
                            </div>
                            <p className="text-secondary cursor-pointer" onClick={forgotPass}>Forgot Password?</p>
                        </div>
                    </div>

                    <button className="w-full bg-[#008BD0] text-white p-[18px] font-semibold text-sm rounded-lg" onClick={signin}>Sign in</button>

                    {/* END::FORM */}

                    {/* START::FOOTER */}
                    <div className="flex items-center justify-center space-x-2">
                        <span>Don't have an account?</span>
                        <p className="font-semibold text-secondary cursor-pointer" onClick={signup}>Sign up</p>
                        {/* <p className="font-semibold text-secondary"  onClick={() => onHideSignup()}>Sign up</p> */}
                    </div>
                    {/* END::FOOTER */}

                </div>
                {/* END::DIALOG */}

            </section >
            {
                signupModal &&
                <Register
                    showSignup={signupModal}
                    onHideSignnew={closeSignup}
                />
            }
        </>
    )
}

export default Login
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { STATE } from "../../constants";
import { ApiDelete, ApiGet, ApiPost, ApiPut } from "../../helper/API/ApiData";
import { PERMISSION_TYPE, userPermission } from "../../helper/permission";
import { RootState } from "../../redux/store";
import Select from "react-select";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column} from "primereact/column";
import { TieredMenu } from "primereact/tieredmenu";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { toast } from "react-toastify";
import { Tooltip } from "primereact/tooltip";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import { useLocation, useParams, useNavigate } from "react-router";
import _, { get } from "lodash";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dropdown } from "primereact/dropdown";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";

function PayoutGrid() {
const params = useParams();
  const { id } = params;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const source = searchParams.get('source');
  const username = searchParams.get('username');
  let cid;
  let uid;
  if(source =='connector'){
    cid=id;
  }
  else{
   uid=id;
  }
  const loginCodeListData = {
    logincodelistid: "",
    normal_payout: "",
    capping: "",
    logincodelistid_error: null,
    normal_payout_error: null,
    capping_error: null,
  };

  const [loader, setLoader] = useState(true);
  const [normalSlot, setNormalSlot] = React.useState({
    normal: "",
    normal_payout_error: null,
    capping: "",
    capping_error: null,
    logincodelist_id: "",
    user_id: "",
  });
  const [userid, setUserid] = React.useState(uid);
  const [termsbaseCondition, setbaseTermsCondition] = React.useState([]);
  const [termsConditionType, setTermsConditionType] = React.useState("");
  const [editOpen, setEditOpen] = React.useState(false);
  const [actionOpen, setActionOpen] = React.useState<boolean[]>([]);
  const [termsOpen, setTermsOpen] = React.useState<boolean[]>([]);
  const [termsAdvanceOpen, setTermsAdvanceOpen] = React.useState<boolean[]>([]);
  const [termsAdvanceHoverOpen, setTermsAdvanceHoverOpen] = React.useState<
    boolean[]
  >([]);
  const [bankData, setBankData] = useState<any>({});
  const [editbankData, setEditBankData] = useState<any>({});
  const [editTnCModal, setEditTnCModal] = React.useState(false);
  const [addTnCModal, setAddTnCModal] = React.useState(false);
  const [addTnCOpen, setAddTnCOpen] = React.useState(false);
  const [termData, setTermData] = useState<any>("");
  const [baseTerms, setBaseTerms] = React.useState([]);
  const [rowTerms, setRowTerms] = React.useState([]);
  const [selectedLoginCodeListId, setSelectedLoginCodeListId] =
    React.useState("");
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [payoutGridDetail, setPayoutGridDetail] = useState([]);
  const [connectorformData, setConnectorFormData] = useState<any>([]);
  const data={
    id:'',
   bank_name:'',
   product_name:'',
   Normal:'',
   LoginCode:'',
   Advance:'',
   SRNO:null
  }
  const [readOnly,setReadOnly]=useState(true)
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [addFormData,setAddFormData] =useState<any>(data)
  const [permission, setPermission] = useState<any>({});
  const user = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [historyModel, setHistoryModel] = React.useState(false);
  const [historyData, setHistoryData] = React.useState([]);
  const [addPayloadModel, setAddPayloadModel] = React.useState(false);
  const [formData, setFormData] = useState<any>(loginCodeListData);
  const [codeData, setCodeData] = useState<any>([]);
  const [newcodeData, setNewCodeData] = useState<any>([]);
  const [termAndConditionData, setTermAndCondition] = useState({
    termAndCondition: "",
    termAndCondition_error: "",
  });
  const [bankNameOptions, setBankNameOptions] = useState<any>([]);
  const [productOptions, setProductNameOptions] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeTab, setActiveTab] = useState("bronze");
  const [displayAddpayout, setDisplayAddpayout] = useState<boolean>(true);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    pagination:true,
    search: "",
    filters: {
      bank_name: { value: null, matchMode: FilterMatchMode.IN },
      product_name: { value: null, matchMode: FilterMatchMode.IN },
    },
  });
  const [pagination,setPagination]=useState(false)
  const [connectorpayout, setConnectorPayout]=useState(false);
    const op = useRef(null);
  const [connectorName,setConnectorName]=useState(null)
  const [exportData,setExportData]=useState(false);
  const [exportType,setExportType]=useState('');
  let date=new Date().toDateString();
  const [newdate,setDate]=useState(moment(date).format('DD-MM-YY'))

  const navigate = useNavigate();
  const getTermsAndCondition = (logincodelistToFind: any) => {
    let temp: any = [];
    let data: any = [];
    temp = [...temp, ...termsbaseCondition];
    const foundItem = temp.find(
      (item: any) => item.logincodelist === logincodelistToFind
    );
    const baseItem = temp.find((item: any) => item.logincodelist === "Base");
    if (foundItem) {
      data = [
        ...foundItem.jsonb_agg.map((agg: any) => agg.term_and_condition),
        ...baseItem.jsonb_agg.map((agg: any) => agg.term_and_condition),
      ];
      return data;
    } else {
      if (baseItem) {
        return baseItem.jsonb_agg.map((agg: any) => agg.term_and_condition);
      } else {
        return [""];
      }
    }
  };

  const columns = [
    { id: "SRNO", label: "SR.NO." },
    { id: "bank_name", label: "Bank Name" },
    { id: "product_name", label: "Product" },
    { id: "Normal", label: "Normal Payout Slab" },
    { id: "Advance", label: "Advance Payout Slab" },
  ];

  const export_columns = [
    { id: "SRNO", label: "SR.NO." },
    { id: "bank_name", label: "Bank/NBFC Name" },
    { id: "product", label: "Product" },
    { id: "dsa_code", label: "DSA Code" },
    { id: "bronze_payout", label: "Base Payout Grid" },
    { id: "silver_payout", label: "Silver Payout Grid" },
    { id: "gold_payout", label: "Gold Payout Grid" },
  ];
  const getBaseTerms = () => {
    let temp: any = [];
    temp = [...temp, ...termsbaseCondition];
    const baseItem = temp.find((item: any) => item.logincodelist === "Base");
    setBaseTerms(baseItem.jsonb_agg);
  };

  const getMainTerms = (logincodelistToFind: any) => {
    let temp: any = [];
    temp = [...temp, ...termsbaseCondition];
    const foundItem = temp.find(
      (item: any) => item.logincodelist === logincodelistToFind
    );
    setSelectedLoginCodeListId(logincodelistToFind);
    setRowTerms(foundItem?.jsonb_agg);
  };

 const  getResetData=(string)=>{
  setLoader(true);
  const modifiedLazyState = { ...lazyState, limit: lazyState.rows,pagination:false };
  delete modifiedLazyState.rows;
  delete modifiedLazyState["multiSortMeta"];
  delete modifiedLazyState["sortField"];
  delete modifiedLazyState["sortOrder"];

  // Function to replace empty arrays in the "value" field with null
  const replaceEmptyValueArrays = (obj) => {
    for (const key in obj) {
      if (
        key === "value" &&
        Array.isArray(obj[key]) &&
        obj[key].length === 0
      ) {
        obj[key] = null;
      } else if (typeof obj[key] === "object") {
        replaceEmptyValueArrays(obj[key]);
      }
    }
    return obj;
  };

  // Call the function to replace empty arrays in the "value" field with null
  const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
  finalModifiedLazyState.page++;
  finalModifiedLazyState["user_id"] = userid;
      ApiPost(`payoutgrid/get-payout?id=${user._id}&level=${string}`, finalModifiedLazyState)
        .then((res: any) => {
          let temp = res?.data?.result.map((item: any, index: number) => {
            return {
              id: item._id,
              SRNO: lazyState.first + index + 1,
              bank_name: item?.bank_name,
              product_name: item?.product,
              Normal: item?.normal_payout,
              Advance: item?.advance_payout,
              LoginCode: item?.logincodelist_id,
            };
          });
          setConnectorFormData(temp)
          bankDataOptions(temp);
          setLoader(false);
        })
        .catch((error) => {
          const tempPermission = {
            [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(
              user,
              PERMISSION_TYPE.ADD_PAYOUT_GRID
            ),
            [PERMISSION_TYPE.CONNECTOR]: userPermission(
              user,
              PERMISSION_TYPE.CONNECTOR
            ),
          };
          setPermission(tempPermission);
          setLoader(false);
        });
    
  }

   const bankDataOptions=(data)=>{
    const tempdata=Object.values(bankData).flat();  
          const newBankData = tempdata?.filter((item :any)=> 
            !data.some(element => element.LoginCode === item.id)
          );  
          const editbankData = _.groupBy(newBankData, function(b:any){
            return b.bank;
          }); 
          setEditBankData(editbankData)
  }
  const getConnectorPayout=()=>{
    setLoader(true);
    let modifiedLazyState;
    if(cid){
      setPagination(false)
      modifiedLazyState = { ...lazyState, limit: lazyState.rows,pagination:false };
    }
    else{
      setPagination(true)
      modifiedLazyState = { ...lazyState, limit: lazyState.rows,pagination:true };
    }

    // setPagination(false)
    // const modifiedLazyState = { ...lazyState, limit: lazyState.rows,pagination:false };
    
    delete modifiedLazyState.rows;
    delete modifiedLazyState["multiSortMeta"];
    delete modifiedLazyState["sortField"];
    delete modifiedLazyState["sortOrder"];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
        if (
          key === "value" &&
          Array.isArray(obj[key]) &&
          obj[key].length === 0
        ) {
          obj[key] = null;
        } else if (typeof obj[key] === "object") {
          replaceEmptyValueArrays(obj[key]);
        }
      }
      return obj;
    };

    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;
    finalModifiedLazyState["user_id"] = userid;

    const tempPermission = {
      [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(
        user,
        PERMISSION_TYPE.ADD_PAYOUT_GRID
      ),
      [PERMISSION_TYPE.CONNECTOR]: userPermission(
        user,
        PERMISSION_TYPE.CONNECTOR
      ),
      [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.USER]: userPermission(user, PERMISSION_TYPE.USER),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(user, PERMISSION_TYPE.STAFF_OPS),
    };
    setPermission(tempPermission);
    if(tempPermission[PERMISSION_TYPE.CONNECTOR] || cid){
      let id;
      if(cid){
        id=cid;
      }
      else{
        id=user._id;
      }
      ApiPost(`payoutgrid/get-connector-payout?id=${id}`,finalModifiedLazyState)
        .then((res: any) => {
          setTotalRecords(Number(res.data.count[0].count));
            let temp = res?.data?.data?.map((item: any, index: number) => {
            return {
              id: item._id,
              SRNO: lazyState.first + index + 1,
              bank_name: item?.bank_name,
              product_name: item?.product,
              Normal: item?.normal_payout,
              Advance: item?.advance_payout,
              LoginCode: item?.logincodelist_id,
            };
          });
          // setPayoutGridDetail(temp);
          setConnectorFormData(temp)
          if(exportData){
            generateExport(temp)
          }
          bankDataOptions(temp);
          setActionOpen(
            temp.map(() => {
              return false;
            })
          );
          setTermsOpen(
            temp.map(() => {
              return false;
            })
          );
          setTermsAdvanceOpen(
            temp.map(() => {
              return false;
            })
          );
          setTermsAdvanceHoverOpen(
            temp.map(() => {
              return false;
            })
          );
          setState({
            ...state,
            totalItems: res.data.count[0].count,
          });
          setLoader(false);
        })
        .catch((error) => {
          const tempPermission = {
            [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(
              user,
              PERMISSION_TYPE.ADD_PAYOUT_GRID
            ),
            [PERMISSION_TYPE.CONNECTOR]: userPermission(
              user,
              PERMISSION_TYPE.CONNECTOR
            ),
          };
          setPermission(tempPermission);
          setLoader(false);
        });
    }
  }
  const getPayoutGrid = (activeTab) => {
    setLoader(true);
    let modifiedLazyState;
    if(pagination){
        modifiedLazyState = { ...lazyState, limit: lazyState.rows , pagination:true };
    }
    else{
        modifiedLazyState = { ...lazyState, limit: lazyState.rows ,pagination:false};
    }
    
    delete modifiedLazyState.rows;
    delete modifiedLazyState["multiSortMeta"];
    delete modifiedLazyState["sortField"];
    delete modifiedLazyState["sortOrder"];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
      for (const key in obj) {
        if (
          key === "value" &&
          Array.isArray(obj[key]) &&
          obj[key].length === 0
        ) {
          obj[key] = null;
        } else if (typeof obj[key] === "object") {
          replaceEmptyValueArrays(obj[key]);
        }
      }
      return obj;
    };

    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;
    finalModifiedLazyState["user_id"] = userid;

    const tempPermission = {
      [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(
        user,
        PERMISSION_TYPE.ADD_PAYOUT_GRID
      ),
      [PERMISSION_TYPE.CONNECTOR]: userPermission(
        user,
        PERMISSION_TYPE.CONNECTOR
      ),
      [PERMISSION_TYPE.ADMIN]: userPermission(user, PERMISSION_TYPE.ADMIN),
      [PERMISSION_TYPE.USER]: userPermission(user, PERMISSION_TYPE.USER),
      [PERMISSION_TYPE.STAFF_OPS]: userPermission(user, PERMISSION_TYPE.STAFF_OPS),
      [PERMISSION_TYPE.STAFF]: userPermission(user, PERMISSION_TYPE.STAFF),

    };
    setPermission(tempPermission);

    if (tempPermission[PERMISSION_TYPE.ADMIN] || tempPermission[PERMISSION_TYPE.STAFF_OPS]) {
      // if(userid){

      // }
      let query = userid
        ? `payoutgrid/get-payout?id=${userid}`
        : `payoutgrid/get-payout?id=${user._id}&level=${activeTab}`;

      ApiPost(query, finalModifiedLazyState)
        .then((res: any) => {
          setTotalRecords(Number(res.data.count[0].count));
          let temp = res?.data?.result.map((item: any, index: number) => {
            return {
              id: item._id,
              SRNO: lazyState.first + index + 1,
              bank_name: item?.bank_name,
              product_name: item?.product,
              Normal: item?.normal_payout,
              Advance: item?.advance_payout,
              LoginCode: item.logincodelist_id
                ? item.logincodelist_id
                : item.logincodelistid,
              Capping: item?.capping_payout,
            };
          });
          if(exportData){
            generateExport(temp)
          }
          setPayoutGridDetail(temp);
          setActionOpen(
            temp.map(() => {
              return false;
            })
          );
          setTermsOpen(
            temp.map(() => {
              return false;
            })
          );
          setTermsAdvanceOpen(
            temp.map(() => {
              return false;
            })
          );
          setTermsAdvanceHoverOpen(
            temp.map(() => {
              return false;
            })
          );
          setState({
            ...state,
            totalItems: res.data.count[0].count,
          });
          setLoader(false);
        })
        .catch((error) => {
          const tempPermission = {
            [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(
              user,
              PERMISSION_TYPE.ADD_PAYOUT_GRID
            ),
            [PERMISSION_TYPE.CONNECTOR]: userPermission(
              user,
              PERMISSION_TYPE.CONNECTOR
            ),
          };
          setPermission(tempPermission);
          setLoader(false);
        });
    }     
    else if(!tempPermission[PERMISSION_TYPE.CONNECTOR]){
      ApiPost(`payoutgrid/get-payout?id=${user._id}`, finalModifiedLazyState)
        .then((res: any) => {
          setTotalRecords(Number(res.data.count[0].count));
          let temp = res?.data?.result.map((item: any, index: number) => {
            return {
              id: item._id,
              SRNO: lazyState.first + index + 1,
              bank_name: item?.bank_name,
              product_name: item?.product,
              Normal: item?.normal_payout,
              Advance: item?.advance_payout,
              LoginCode: item?.logincodelistid,
              Capping: item?.capping_payout,
            };
          });
          // temp.map((data)=>{
          //    data.id== cid;
          // })
          setPayoutGridDetail(temp);
          if(exportData){
            generateExport(temp)
          }
          setActionOpen(
            temp.map(() => {
              return false;
            })
          );
          setTermsOpen(
            temp.map(() => {
              return false;
            })
          );
          setTermsAdvanceOpen(
            temp.map(() => {
              return false;
            })
          );
          setTermsAdvanceHoverOpen(
            temp.map(() => {
              return false;
            })
          );
          setState({
            ...state,
            totalItems: res.data.count[0].count,
          });
          setLoader(false);
        })
        .catch((error) => {
          const tempPermission = {
            [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(
              user,
              PERMISSION_TYPE.ADD_PAYOUT_GRID
            ),
            [PERMISSION_TYPE.CONNECTOR]: userPermission(
              user,
              PERMISSION_TYPE.CONNECTOR
            ),
          };
          setPermission(tempPermission);
          setLoader(false);
        });
    }
  };
  
  const getExportPayoutData = async () => {
    if(userid === undefined && permission[PERMISSION_TYPE.ADMIN] )
    await ApiGet(
      `payoutgrid/get-export-payout`
    ).then((res: any) => {
      let temp = res?.data?.result?.map((item: any, index: number) => {
        return {
          ...item,
        };
      });
      if(exportData){
      generateExport(temp)
      setExportData(false)
    }
    });
  };
  const generateExport=(data)=>{
    let final_columns: any[];
        if(permission[PERMISSION_TYPE.ADMIN] && userid === undefined){
          final_columns=export_columns
        }else {
          final_columns=columns
        }
        setExportData(false)
    if(exportType === 'pdf'){
      const doc = new jsPDF('landscape', 'mm', 'a4');
                   
      let width = doc.internal.pageSize.width;
      let height = doc.internal.pageSize.height;

        const img = new Image();
        img.src = 'assets/logo.png'; 
        img.onload =function(){
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.globalAlpha = 0.2;

         ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
          const watermarkBase64 = canvas.toDataURL("image/png");

          const maxWidth = width * 0.5;
          const maxHeight = height * 0.5; 
          let newWidth = img.width;
          let newHeight = img.height;

          if (newWidth > maxWidth || newHeight > maxHeight) {
            const widthRatio = maxWidth / newWidth;
            const heightRatio = maxHeight / newHeight;
            const scale = Math.min(widthRatio, heightRatio);
    
            newWidth = newWidth * scale;
            newHeight = newHeight * scale;
        }

        
        // Calculate the center position
        let xPos = (width - newWidth) / 2;
        let yPos = (height - newHeight) / 2;
                    // doc.addImage(img, 'PNG', width / 2 - 100, height / 2 - 100, 200, 200, undefined, 'SLOW');
                    autoTable( doc,{ head: [final_columns.map(col => col.label)],
                    body: data.map((product,index)=> final_columns.map((col) =>{
                          if(col.id === 'SRNO'){
                            return  index+1;
                          }
                          else
                           {
                          return   product[col.id]
                          }
                      })),styles: {
                              fontSize:11,
                              cellPadding: 3,
                              textColor:[0,0,0]
                          },
                          headStyles: {
                              fillColor: [52, 152, 219],
                              textColor: [255, 255, 255]
                          },
                          margin: { top: 15 },
                          theme: 'grid',
                          didDrawPage: function (data) {
                            doc.addImage(watermarkBase64, 'PNG',xPos, yPos, newWidth, newHeight);
      
                      }}
                );
               
                doc.save(`PayoutGrid_${newdate}.pdf`);
              }
              setPagination(true)  ;               
              setLoader(false);
    }
    else{
      const csvRows = [];
      const modifiedData = data.map((product, index) => {
        // Create a row with custom logic for "SRNO"
        const row = columns.map(col => {

          if (col.id === "SRNO") {
            return index + 1; // Add serial number
          }
           else {
            return product[col.id]; // Add product data
          }
        });
    
        return row;
      });
      csvRows.push(columns.map(col => col.label));

      modifiedData.forEach(row => {
        csvRows.push(
          row
        );
      });

  const csvContent = 'data:text/csv;charset=utf-8,' + csvRows.join('\n');
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', `PayoutGrid_${newdate}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click();
  document.body.removeChild(link);
      setPagination(true)  ;               
      setLoader(false);

    }

  }
  const fetchDropdownOptions = () => {
    setLoader(true);
    ApiGet(`options/payout`).then((res: any) => {
      const banks = convertToObjectArray(res.data.banks);
      const products = convertToObjectArray(res.data.products);

      setBankNameOptions(banks);
      setProductNameOptions(products);
    });
  };

  const convertToObjectArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      label: obj[key],
      value: key,
    }));
  };

  // const getLevelPayout = async (level) => {

  //   // const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
  //   // finalModifiedLazyState.page++;
  //   // finalModifiedLazyState['user_id'] = userid;

  //   ApiPost(`payoutgrid/get-payout?id=${userid?userid:user._id}&level=${level}`,{})
  //     .then((res: any) => {
  //       setTotalRecords(Number(res.data.count[0].count));
  //       let temp = res?.data?.result.map((item: any, index: number) => {
  //         return {
  //           id: item._id,
  //           SRNO: (lazyState.first) + index + 1,
  //           bank_name: item?.bank_name,
  //           product_name: item?.product,
  //           Normal: item?.normal_payout,
  //           Advance: item?.advance_payout,
  //           LoginCode: item?.logincodelistid,
  //           Capping: item?.capping_payout
  //         }
  //       })

  //       setPayoutGridDetail(temp);

  //       setActionOpen(temp.map(() => { return false }))
  //       setTermsOpen(temp.map(() => { return false }))
  //       setTermsAdvanceOpen(temp.map(() => { return false }))
  //       setTermsAdvanceHoverOpen(temp.map(() => { return false }))
  //       setState({
  //         ...state,
  //         totalItems: res.data.count[0].count
  //       });
  //       setLoader(false);
  //     }).catch((error) => {
  //       const tempPermission = {
  //         [PERMISSION_TYPE.ADD_PAYOUT_GRID]: userPermission(user, PERMISSION_TYPE.ADD_PAYOUT_GRID),
  //         [PERMISSION_TYPE.CONNECTOR]: userPermission(user, PERMISSION_TYPE.CONNECTOR),
  //       }
  //       setPermission(tempPermission);
  //       setLoader(false);
  //     })
  // }

  const codeDataOptions = codeData.map((c: any) => ({
    ...c,
    label: c.bank_name + " - " + c.product,
    value: c._id,
  }));
  const newcodeDataOptions = newcodeData.map((c: any) => ({
    ...c,
    label: c.bank_name + " - " + c.product,
    value: c._id,
  }));
  const getTermsAndConditions = async () => {
    await ApiGet(`terms/get-condition`).then((res: any) => {
      setbaseTermsCondition(res?.data.result);
    });
  };
  const getCodeList = async () => {
    await ApiGet(`loginCodeList/option-codelist?source=payoutgrid`).then(
      (res: any) => {
        setCodeData(Object.values(res.data.result));
      }
    );
  };

  const fetchCodeList = () => {
    ApiGet(`loginCodeList/option-codelist`).then((res: any) => {
      if (res) {
        setNewCodeData(res.data.result);
      }
    });
  };
  useEffect(() => {
    Promise.all([
      getPayoutGrid(activeTab),
      getConnectorPayout(),
      fetchDropdownOptions(),
      getTermsAndConditions(),
      getCodeList(),
      setUserid(id),
      getLoginCodeList(),
      fetchCodeList(),
    ])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, [lazyState, id]);

  useEffect(() => {
    Promise.all([
     getExportPayoutData()
    ])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, [exportData]);

  const editPayout = () => {
    let error = { ...normalSlot };

    if (!normalSlot.normal) {
      error = { ...error, normal_payout_error: "Please enter Normal Payout" };
    }

    if (!userid) {
      if (!normalSlot.capping) {
        error = { ...error, capping_error: "Please enter Capping" };
      }
    } else {
      error.capping = "";
      error.capping_error = null;
      error.user_id = null;
    }

    setNormalSlot(error);
    if (userid) {
      if (
        !error.normal_payout_error &&
        (!userid || (userid && !error.capping_error))
      ) {
        if(user.role == "staff_operations" || user.role == "staff_sales"){

         let payload={
          ...normalSlot, level: activeTab ,          
         } 
         const finalPayload={
          changes:payload,
          title: `Update Payout for ${username}`,
          method:'Put',
          entity_id:null,
          url:'payoutgrid/edit-payout',
          entity_type:'payoutgrid',
		      operation:'update',
        }  
        ApiPost("ApprovalRequest/approval-request", finalPayload)
          .then((res: any) => {
            if (res ) {
                if(res.apiInjection === true){
                  console.log("callleddd    ")
                ApiPut(`payoutgrid/edit-payout`, { ...normalSlot, level: activeTab })
          .then((res: any) => {
            if (res && res.success) {
              toast.success("Updated successfully", {
                position: "top-right",
                theme: "colored",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
              }
              toast.success(res.message, {
                position: "top-right",
                theme: "colored",
              });

            }
            setEditOpen(false);
            getPayoutGrid(activeTab);
          })
          .catch((error) => {
            console.log(error);
          });
        }
        else{
          ApiPut(`payoutgrid/edit-payout`, { ...normalSlot, level: activeTab })
          .then((res: any) => {
            if (res && res.success) {
              toast.success("Updated successfully", {
                position: "top-right",
                theme: "colored",
              });
            }
            setEditOpen(false);
            getPayoutGrid(activeTab);
          })
          .catch((error) => {
            console.log(error);
          });}
      }
    } else {
      if (
        !error.normal_payout_error &&
        (!userid || (userid && !error.capping_error))
      ) {
        if(user.role == "staff_operations"){
          let payload={
            ...normalSlot, level: activeTab             
           } 
           const finalPayload={
            changes:payload,
            title: `Update Payout-level`,
            method:'Put',
            entity_id:null,
            url:'payoutgrid/edit-payout-level',
            entity_type:'payoutgrid',
		        operation:'update'
          }  
          ApiPost("ApprovalRequest/approval-request", finalPayload)       
          .then((res: any) => {
            if (res ) {
              
              toast.success(res.message, {
                position: "top-right",
                theme: "colored",
              });
            }
            setEditOpen(false);
            getPayoutGrid(activeTab);
          })
          .catch((error) => {
            console.log(error);
          });
        }
        else{
          ApiPut(`payoutgrid/edit-payout-level`, {
            ...normalSlot,
            level: activeTab,
          })
            .then((res: any) => {
              if (res && res.success) {
                toast.success("Updated successfully", {
                  position: "top-right",
                  theme: "colored",
                });
              }
              setEditOpen(false);
              getPayoutGrid(activeTab);
            })
            .catch((error) => {
              console.log(error);
            });}
      }
    }
  };

  const editTermsClicked = (id: any, termsConditionType: any) => {
    setEditTnCModal(true);
    let term: any;
    if (termsConditionType === "main") {
      term = baseTerms.find((t: any) => t._id == id);
    } else {
      term = rowTerms.find((t: any) => t._id == id);
    }
    setTermAndCondition({
      termAndCondition: term.term_and_condition,
      termAndCondition_error: "",
    });
    setCurrentId(term._id);
    setTermData(term);
  };

  const [deleteConfirmVisible, setDeleteConfirmVisible] =
    useState<boolean>(false);
  const [currentId, setCurrentId] = useState<any>("");
  const toastRef = useRef<Toast>(null);

  const accept = () => {
    deleteTnC();
  };

  const reject = () => {
    setDeleteConfirmVisible(false);
  };

  const handleDelete = (id: any) => {
    setCurrentId(id);
    setDeleteConfirmVisible(true);
  };

  const deleteTnC = () => {

    ApiDelete("terms/delete-condition?id=" + currentId)
      .then((res: any) => {
        if (res && res.success) {
          toast.success("Deleted successfully", {
            position: "top-right",
            theme: "colored",
          });
        }
        setEditTnCModal(false);
        setAddTnCModal(false);
        setAddTnCOpen(false);
        getPayoutGrid(activeTab);
        getTermsAndConditions();
      })
      .catch(() => {
        console.log("Something went wrong");
      });
    // if(user.role == "staff_operations"){
    // ApiDelete("terms/delete-condition?id=" + currentId)
    //   .then((res: any) => {
    //     if (res && res.success) {
    //       toast.success("Deleted successfully", {
    //         position: "top-right",
    //         theme: "colored",
    //       });
    //     }
    //     setEditTnCModal(false);
    //     setAddTnCModal(false);
    //     setAddTnCOpen(false);
    //     getPayoutGrid(activeTab);
    //     getTermsAndConditions();
    //   })
    //   .catch(() => {
    //     console.log("Something went wrong");
    //   });
    // }
    // else{
    //   ApiDelete("terms/delete-condition?id=" + currentId)
    //   .then((res: any) => {
    //     if (res && res.success) {
    //       toast.success("Deleted successfully", {
    //         position: "top-right",
    //         theme: "colored",
    //       });
    //     }
    //     setEditTnCModal(false);
    //     setAddTnCModal(false);
    //     setAddTnCOpen(false);
    //     getPayoutGrid(activeTab);
    //     getTermsAndConditions();
    //   })
    //   .catch(() => {
    //     console.log("Something went wrong");
    //   });}
  };

  const UpdateTermsAndConditions = () => {
    let updatedForm = { ...termAndConditionData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] !== "object" && updatedForm[key] !== null) {
        updatedForm[key] = updatedForm[key].trim();
      }
    }
    if (!termAndConditionData.termAndCondition) {
      updatedForm = {
        ...termAndConditionData,
        termAndCondition_error: "Please Add Term and condition",
      };
    }
    setTermAndCondition(updatedForm);
    if (updatedForm.termAndCondition) {
      if (termsConditionType === "main") {
        ApiPut(`terms/edit-condition?id=${currentId}`, {
          term_and_condition: updatedForm.termAndCondition,
        }).then((res: any) => {
          if (res && res.success) {
            toast.success("Updated successfully", {
              position: "top-right",
              theme: "colored",
            });
          }
          setEditTnCModal(false);
          setAddTnCOpen(false);
          getPayoutGrid(activeTab);
          getTermsAndConditions();
        });
      //   if(user.role == "staff_operations"){
      //   ApiPut(`terms/edit-condition?id=${currentId}`, {
      //     term_and_condition: updatedForm.termAndCondition,
      //   }).then((res: any) => {
      //     if (res && res.success) {
      //       toast.success("Updated successfully", {
      //         position: "top-right",
      //         theme: "colored",
      //       });
      //     }
      //     setEditTnCModal(false);
      //     setAddTnCOpen(false);
      //     getPayoutGrid(activeTab);
      //     getTermsAndConditions();
      //   });
      // }
      // else{
      //   ApiPut(`terms/edit-condition?id=${currentId}`, {
      //     term_and_condition: updatedForm.termAndCondition,
      //   }).then((res: any) => {
      //     if (res && res.success) {
      //       toast.success("Updated successfully", {
      //         position: "top-right",
      //         theme: "colored",
      //       });
      //     }
      //     setEditTnCModal(false);
      //     setAddTnCOpen(false);
      //     getPayoutGrid(activeTab);
      //     getTermsAndConditions();
      //   });}
      } else {
        ApiPut(`terms/edit-condition?id=${selectedLoginCodeListId}`, {
          term_and_condition: updatedForm.termAndCondition,
        }).then((res: any) => {
          if (res && res.success) {
            toast.success("Updated successfully", {
              position: "top-right",
              theme: "colored",
            });
          }
          setEditTnCModal(false);
          setAddTnCOpen(false);
          getPayoutGrid(activeTab);
          getTermsAndConditions();
          setSelectedLoginCodeListId("");
        });
      //   if(user.role == "staff_operations"){
      //   ApiPut(`terms/edit-condition?id=${selectedLoginCodeListId}`, {
      //     term_and_condition: updatedForm.termAndCondition,
      //   }).then((res: any) => {
      //     if (res && res.success) {
      //       toast.success("Updated successfully", {
      //         position: "top-right",
      //         theme: "colored",
      //       });
      //     }
      //     setEditTnCModal(false);
      //     setAddTnCOpen(false);
      //     getPayoutGrid(activeTab);
      //     getTermsAndConditions();
      //     setSelectedLoginCodeListId("");
      //   });
      // }
      // else{
      //   ApiPut(`terms/edit-condition?id=${selectedLoginCodeListId}`, {
      //     term_and_condition: updatedForm.termAndCondition,
      //   }).then((res: any) => {
      //     if (res && res.success) {
      //       toast.success("Updated successfully", {
      //         position: "top-right",
      //         theme: "colored",
      //       });
      //     }
      //     setEditTnCModal(false);
      //     setAddTnCOpen(false);
      //     getPayoutGrid(activeTab);
      //     getTermsAndConditions();
      //     setSelectedLoginCodeListId("");
      //   });
      // }
    }
    } else {
      toast.error("Please enter term and condition to proceed!", {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const handleOpenHistoryModel = (id: any) => {
    setHistoryModel(true);
    try {
      {
        userid
          ? ApiGet(
              `payoutGrid/get-viewhistory?logincodlist_id=${id}&user_id=${userid}`
            ).then((res: any) => {
              if (res?.data?.data) {
                setHistoryData(res.data.data);
              }
            })
          : ApiGet(`payoutGrid/get-viewhistory?logincodlist_id=${id}`).then(
              (res: any) => {
                if (res?.data?.data) {
                  setHistoryData(res.data.data);
                }
              }
            );
      }
    } catch (ex) {
      // toast.error("something went wrong", {
      //   position: "top-right",
      //   theme: "colored"
      // })
    }
  };

  const handleProductChange = (selectedOption: any) => {
    setFormData({
      ...formData,
      logincodelistid: selectedOption ? selectedOption.value : "",
      logincodelistid_error: null,
    });
  };

  
  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9.]/g, "");
  };

  const handleSave = () => {
    let updatedForm = { ...formData };

    if (formData.logincodelistid == "") {
      updatedForm = {
        ...updatedForm,
        logincodelistid_error: "Please select Code list",
      };
    }
    if (formData.normal_payout == "") {
      updatedForm = {
        ...updatedForm,
        normal_payout_error: "Please enter Normal Payout",
      };
    }
    if (!formData.capping) {
      updatedForm = { ...updatedForm, capping_error: "Please enter Capping" };
    }

    setFormData(updatedForm);

    if (
      !updatedForm.logincodelistid_error &&
      !updatedForm.normal_payout_error &&
      !updatedForm.capping_error
    ) {
      
      if(user.role == "staff_operations"){
        const payload = {
          logincodelistid: updatedForm.logincodelistid,
          normal_payout: updatedForm.normal_payout,
          capping_payout: updatedForm.capping,
          user_id: userid,
         
        };
        const finalPayload={
          changes:payload,
          title: `Add Payout `,
          method:'Post',
          entity_id:null,
          url:'payoutgrid/add-payout',
          entity_type:'payoutgrid',
		      operation:'add'
        }  
        ApiPost("ApprovalRequest/approval-request", finalPayload).then((res: any) => {
        if (res && res.success) {
          toast.success(res.data, {
            position: "top-right",
            theme: "colored",
          });
        }
        setAddPayloadModel(false);
        getPayoutGrid(activeTab);
        setFormData(loginCodeListData);
      });
    }else{
      const payload = {
        logincodelistid: updatedForm.logincodelistid,
        normal_payout: updatedForm.normal_payout,
        capping_payout: updatedForm.capping,
        user_id: userid,
      };
      ApiPost(`payoutgrid/add-payout`, payload).then((res: any) => {
        if (res && res.success) {
          toast.success("Added successfully", {
            position: "top-right",
            theme: "colored",
          });
        }

        getPayoutGrid(activeTab);
        setFormData(loginCodeListData);
      });
      setAddPayloadModel(false);
    }}
  };

  const onPage = (event) => {
    let filter = { ...lazyState };
    filter = event;
    filter["search"] = lazyState.search;
    setlazyState(filter);
  };

  const onFilter = (event) => {
    event["first"] = 0;
    event["page"] = 0;
    setlazyState(event);
  };

  const onGlobalFilterChange = (e) => {
    const value = e;
    let filter = { ...lazyState };

    filter["search"] = value;

    setlazyState(filter);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onGlobalFilterChange(searchValue);
    }
  };

  const handleSearchClick = () => {
    onGlobalFilterChange(searchValue);
  };

  const handleInputChange = (event) => {
    setSearchValue(event.target.value);
  };

  const exportPdf = () => {
    setPagination(false);
    setExportData(true)
    setExportType('pdf')
    
    if(permission[PERMISSION_TYPE.CONNECTOR]){
getConnectorPayout();
    }if(userid !== undefined   && permission[PERMISSION_TYPE.ADMIN]){
      getPayoutGrid(activeTab)

    }
    if(permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.USER]){
      getPayoutGrid(activeTab)

          }
    if(userid === undefined && permission[PERMISSION_TYPE.ADMIN]){
      getExportPayoutData()
   } 
};

const exportCSV = () => {
  setExportData(true)
  setPagination(false);
  setExportType('csv')
  getPayoutGrid(activeTab)
  
  if(permission[PERMISSION_TYPE.CONNECTOR]){
    getConnectorPayout();
        }
  if(userid !== undefined && permission[PERMISSION_TYPE.ADMIN]){
          getPayoutGrid(activeTab)
        }
        if(permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.USER]){
          getPayoutGrid(activeTab)
              }
        if(userid === undefined && permission[PERMISSION_TYPE.ADMIN]){
          getExportPayoutData()
        } 
};
  const renderHeader = () => {
    return (
      <div className="flex justify-end">
        <div>
        {/* <Button type="button" icon="pi pi-file-pdf" rounded onClick={exportPdf} data-pr-tooltip="PDF" /> */}
        <Button label="Export" icon="pi pi-upload" className="p-button" onClick={(e) => op.current.toggle(e)}>
        <OverlayPanel ref={op}>
                <option style={{ margin: '10px 0',cursor: 'default' }} value='bronze' onClick={exportPdf}>Pdf </option>
                    { (permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF] || permission[PERMISSION_TYPE.STAFF_OPS]) && (<option style={{ margin: '10px 0',cursor: 'default' }} value='silver' onClick={exportCSV}>Csv</option>
                                        )}
                                        </OverlayPanel>
        </Button>
        </div>
        <div>
        <span>
          <InputText
            className="mr-2 ml-3"
            value={searchValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder="Keyword Search"
          />
          <Button icon="pi pi-search" onClick={handleSearchClick} />
        </span>
        </div> 
      </div>
    );
  };

  const header = renderHeader();

  const menuRef = useRef(null);
  const selectedRowData = useRef(null);

  const handleMenuItemClick = (itemLabel) => {
    if (itemLabel === "Edit") {
      setEditOpen(true);
      const data = {
        normal: selectedRowData.current.Normal,
        normal_payout_error: null,
        capping: selectedRowData.current.Capping,
        capping_error: null,
        logincodelist_id: selectedRowData.current.LoginCode,
        user_id: userid,
      };
      setNormalSlot(data);
    } else if (itemLabel === "Add T&C") {
      setTermsConditionType("row");
      getMainTerms(selectedRowData.current.LoginCode);
      setAddTnCOpen(true);
    } else if (itemLabel === "View History") {
      handleOpenHistoryModel(selectedRowData.current.LoginCode);
    }
  };

  const handleEllipsisClick = (event, rowData) => {
    event.stopPropagation(); // Prevent event from bubbling up
    selectedRowData.current = rowData;
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src="../assets/menu-cirlce.svg"
          alt="menu-icon"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleEllipsisClick(e, rowData)}
        />
        <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
      </div>
    );
  };

  const generateMenuItems = () => {
    if (displayAddpayout) {
      const menuItems = [
        { label: "Edit", command: () => handleMenuItemClick("Edit") },
        {
          label: "View History",
          command: () => handleMenuItemClick("View History"),
        },
      ];

      if (!userid) {
        menuItems.splice(1, 0, {
          label: "Add T&C",
          command: () => handleMenuItemClick("Add T&C"),
        });
      }
      return menuItems;
    } else {
      const menuItems = [
        { label: "Edit", command: () => handleMenuItemClick("Edit") },
        // { label: 'View History', command: () => handleMenuItemClick('View History') }
      ];
      return menuItems;
    }

    // return menuItems;
  };

  const bankNameRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={bankNameOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const productRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={productOptions}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="label"
        placeholder="Select"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
        filter
        filterPlaceholder="Search"
      />
    );
  };

  const [tooltipTarget, setTooltipTarget] = useState(null);
  const [tooltipText, setTooltipText] = useState("");
  const [isTooltipOpen, setIsTooltipOpen] = useState(false); // State to track whether the tooltip is open

  const handleIconMouseEnter = (event, rowData) => {
    const termsAndConditions = getTermsAndCondition(rowData.LoginCode);
    setTooltipText(termsAndConditions.join("\n"));
    setTooltipTarget(event.currentTarget);
    setIsTooltipOpen(true); // Set isTooltipOpen to true when the tooltip is opened
  };

  const handleIconMouseLeave = () => {
    setTooltipText("");
    setIsTooltipOpen(false); // Set isTooltipOpen to false when the tooltip is closed
  };

  // Define the tooltip content
  const tooltipContent = (
    <div className="absolute top-[calc(100%+10px)] w-[300px] right-full shadow-lg z-[777] text-[#808080]">
      <div className="flex justify-between bg-primary py-4 px-5">
        <p className="text-white text-sm">Terms</p>
      </div>
      <div className="py-4 px-5 bg-white">
        <ol className="list-decimal ml-5 text-left space-y-4 text-xs">
          {tooltipText ? (
            tooltipText
              .split("\n")
              .map((item, index) => <li key={index}>{item}</li>)
          ) : (
            <li>No Term & Condition</li>
          )}
        </ol>
      </div>
    </div>
  );

  const addNewTermAndCondition = () => {
    setTermAndCondition({
      termAndCondition: "",
      termAndCondition_error: "",
    });
    setAddTnCModal(true);
    setTermData(null);
  };

  const AddTermsAndConditions = () => {
    let updatedForm = { ...termAndConditionData };
    for (let key in updatedForm) {
      if (typeof updatedForm[key] !== "object" && updatedForm[key] !== null) {
        updatedForm[key] = updatedForm[key].trim();
      }
    }

    if (!termAndConditionData.termAndCondition) {
      updatedForm = {
        ...termAndConditionData,
        termAndCondition_error: "Please Add Term and condition",
      };
    }
    setTermAndCondition(updatedForm);
    if (updatedForm.termAndCondition) {
      if (termsConditionType === "main") {
        ApiPost(`terms/add-condition`, {
          term_and_condition: updatedForm.termAndCondition,
        }).then((res: any) => {
          if (res && res.success) {
            toast.success("Added successfully", {
              position: "top-right",
              theme: "colored",
            });
          }
          setAddTnCOpen(false);
          setAddTnCModal(false);
          getPayoutGrid(activeTab);
          getTermsAndConditions();
        });
//         if(user.role === 'staff_operations'){
//         ApiPost(`terms/add-condition`, {
//           term_and_condition: updatedForm.termAndCondition,
//         }).then((res: any) => {
//           if (res && res.success) {
//             toast.success("Added successfully", {
//               position: "top-right",
//               theme: "colored",
//             });
//           }
//           setAddTnCOpen(false);
//           setAddTnCModal(false);
//           getPayoutGrid(activeTab);
//           getTermsAndConditions();
//         });
//       }
// else{
//         ApiPost(`terms/add-condition`, {
//           term_and_condition: updatedForm.termAndCondition,
//         }).then((res: any) => {
//           if (res && res.success) {
//             toast.success("Added successfully", {
//               position: "top-right",
//               theme: "colored",
//             });
//           }
//           setAddTnCOpen(false);
//           setAddTnCModal(false);
//           getPayoutGrid(activeTab);
//           getTermsAndConditions();
//         });}
      } else {
        ApiPost(`terms/add-condition?id=${selectedLoginCodeListId}`, {
          term_and_condition: updatedForm.termAndCondition,
        }).then((res: any) => {
          if (res && res.success) {
            toast.success("Added successfully", {
              position: "top-right",
              theme: "colored",
            });
          }
          setAddTnCOpen(false);
          setAddTnCModal(false);
          getPayoutGrid(activeTab);
          getTermsAndConditions();
          setSelectedLoginCodeListId("");
        });
      //   if(user.role === 'staff_operations'){
      //   ApiPost(`terms/add-condition?id=${selectedLoginCodeListId}`, {
      //     term_and_condition: updatedForm.termAndCondition,
      //   }).then((res: any) => {
      //     if (res && res.success) {
      //       toast.success("Added successfully", {
      //         position: "top-right",
      //         theme: "colored",
      //       });
      //     }
      //     setAddTnCOpen(false);
      //     setAddTnCModal(false);
      //     getPayoutGrid(activeTab);
      //     getTermsAndConditions();
      //     setSelectedLoginCodeListId("");
      //   });
      // }
      // else{
      //   ApiPost(`terms/add-condition?id=${selectedLoginCodeListId}`, {
      //     term_and_condition: updatedForm.termAndCondition,
      //   }).then((res: any) => {
      //     if (res && res.success) {
      //       toast.success("Added successfully", {
      //         position: "top-right",
      //         theme: "colored",
      //       });
      //     }
      //     setAddTnCOpen(false);
      //     setAddTnCModal(false);
      //     getPayoutGrid(activeTab);
      //     getTermsAndConditions();
      //     setSelectedLoginCodeListId("");
      //   });}
      }
    } else {
      toast.error("Please enter term and condition to proceed!", {
        position: "top-right",
        theme: "colored",
      });
    }
  };
  const getLoginCodeList = async () => {
    await ApiGet(`loginCodeList/option-codelist`)
      .then((res: any) => {
        let temp = res.data.result.map((item: any, index: number) => {
          return {
            bank: item.bank_name,
            product: item.product,
            id: item._id,
          };
        });
        const bankData = _.groupBy(temp, function (b) {
          return b.bank;
        });
        setBankData(bankData);
      })
      .catch((error) => {
        setBankData({});
      });
  };

 const deletePayout=()=>{
  let newData=connectorformData.filter((item)=>        
      !selectedUsers.includes(item)
  )
  newData=newData.map((data,index)=>({
   ...data,
   SRNO:index+1
  }))
  setConnectorFormData({newData})
  setConnectorFormData([...newData])
  setSelectedUsers(null)
  bankDataOptions(newData)
}
const saveEditPayout=()=>{
  let payload;
  payload=connectorformData.map((data)=>{
    return {
      logincodelist_id: data.LoginCode,
      normal_payout:data.Normal,
      advance_payout:data.Advance
    }

  })
  
  ApiPost(`connector/add-connector-payout?id=${cid}`, {payout: payload}).then((res: any) => {
    if (res && res.success) {
      getConnectorPayout();
      setReadOnly(!readOnly)
      toast.success("Added successfully", {
        position: "top-right",
        theme: "colored",
      });
    }
    
  });
}

const renderSRNO = (rowData, column) => {
  return lazyState.first + column.rowIndex + 1;
};
const savePayout=()=>{

  // addFormData['SRNO']= connectorformData.length + 1;
  setAddFormData({...addFormData, Advance:(addFormData.normal-0.25).toString})
      // setConnectorFormData({...connectorformData,addFormData})
    connectorformData.push(addFormData)
    setConnectorFormData([...connectorformData])
    setConnectorPayout(false)
    bankDataOptions(connectorformData);

}
  const bankTemplate =(option)=>{
return (
  <div>
    <select
                        name="financer_name"
                        value={connectorformData.bank_name}
                        onChange={(e) =>
                          option.editorCallback(e.target.value)  
                          // setAddFormData({...connectorformData, bank_name:e.target.value,product_name:''})               
                        }
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="" selected>
                          Select Bank/NBFC Name
                        </option>
                        {Object.keys(bankData)?.map((bank: any, id: any) => (
                          <option value={bank}>{bank}</option>
                        ))}
                      </select>
                                            {/* <Select
                                            options={bankNameOptions}
                                            value={bankNameOptions.find((e)=> e.value === option.value)}
                                            onChange={(e )=>{ 
                                              option.editorCallback(e.value)  
                                              console.log('callleddddddddddd')               
                                            }}
                                            className="w-full text-sm py-2"
                                            placeholder="Type to search..."
                                            // styles={customStyles(formData.department_error)}
                                        /> */}
                                        </div>
);
  }

  const productTemplate =(option)=>{
   
    return (
      <div>
        <select
                        name="product"
                        value={option.value}
                        onChange={(e) =>{
                          option.editorCallback(e.target.value)
                          // setConnectorFormData({
                          //   ...connectorformData,
                          //   LoginCode: e.target.value,
                          // }) 
                        }
                          
                        }
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="" selected>
                          Select Product
                        </option>
                        {bankData?.[option.rowData.bank_name]?.map(
                          (item: any) => (
                            <option value={item.id}>{item.product}</option>
                          )
                        )}
                      </select>
                                                {/* <Select
                                                options={productOptions}
                                                value={productOptions.find((e)=> e.value === option.value)}
                                                onChange={(e )=>{ 
                                                  option.editorCallback(e.value)  
                                                  console.log('callleddddddddddd')               
                                                }}
                                                className="w-full text-sm py-2"
                                                placeholder="Type to search..."
                                                // styles={customStyles(formData.department_error)}
                                            /> */}
                                            </div>
    );
      }
      const textEditor = (options) => {
        return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} onKeyDown={(e) => e.stopPropagation()} />;
    };

      const addBlankRow = () => {
        setConnectorPayout(true)
        setAddFormData(data)
      };
  const customStyles = (hasError) => ({
    control: (base, state) => ({
      ...base,
      borderColor: hasError ? "red" : base.borderColor,
      "&:hover": {
        borderColor: hasError ? "red" : base.borderColor,
      },
    }),
  });

  return (
    <div className="overflow-x-hidden">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          {/* START::MAIN CONTENT */}
          {/* <main className="lg:pl-[280px] pt-[92px]"> */}
          <div className="p-4 sm:p-9 space-y-5  min-h-[calc(100vh-70px)]">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
              {cid && <button  className={`mt-2`} onClick={()=>{ navigate(`/connector`)}}> <i className="pi pi-arrow-circle-left" style={{ fontSize: '2.0rem' }}></i> </button>}
                <h1 className="text-[22px] text-light-gray font-semibold">
                { cid ? `Payout Grid - ${username}` :'Payout Grid' }
                </h1>
                {permission[PERMISSION_TYPE.ADD_PAYOUT_GRID] && !userid && (
                  <>
                    <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                      <li>
                        <button
                          className={`py-2 px-7 text-xs font-medium`}
                          onClick={() => {
                            //setAddTermsConditionMulti(termsCondition)
                            setTermsConditionType("main");
                            // setTcMOpen(true)
                            getBaseTerms();
                            setAddTnCOpen(true);
                          }}
                        >
                          Add T&C
                        </button>
                      </li>
                    </ul>
                    {displayAddpayout && (
                      <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                        <li>
                          <button
                            className={`py-2 px-7 text-xs font-medium`}
                            onClick={() => {
                              //setAddTermsConditionMulti(termsCondition)
                              setAddPayloadModel(true);
                              setFormData(loginCodeListData);
                            }}
                          >
                            Add Payload Grid
                          </button>
                        </li>
                      </ul>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                {/* <h1 className="text-[22px] text-light-gray font-semibold">{permission[PERMISSION_TYPE.ADMIN] ? "Master MIS" : "Dashboard"}</h1> */}

                {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS]) && !userid && (
                  <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                    <li>
                      <button
                        className={`min-w-[170px] py-2 px-7 text-xs font-medium ${
                          activeTab === "bronze" &&
                          "bg-white rounded-[18px] text-primary"
                        }`}
                        onClick={() => {
                          setDisplayAddpayout(true);
                          setActiveTab("bronze");
                          //  getPayoutGrid(activeTab)
                          getPayoutGrid("bronze");
                        }}
                      >
                        Bronze
                      </button>
                    </li>
                    <li>
                      <button
                        className={`min-w-[170px] py-2 px-7 text-xs font-medium ${
                          activeTab === "silver" &&
                          "bg-white rounded-[18px] text-primary"
                        }`}
                        onClick={() => {
                          setDisplayAddpayout(false);
                          setActiveTab("silver");
                          getPayoutGrid("silver");
                        }}
                      >
                        Silver
                      </button>
                    </li>
                    <li>
                      <button
                        className={`min-w-[170px] py-2 px-7 text-xs font-medium ${
                          activeTab === "gold" &&
                          "bg-white rounded-[18px] text-primary"
                        }`}
                        onClick={() => {
                          setDisplayAddpayout(false);
                          setActiveTab("gold");
                          getPayoutGrid("gold");
                        }}
                      >
                        Gold
                      </button>
                    </li>
                  </ul>
                )}
                { cid && (
                  <>
                  <ul className="flex space-x-[18px]">
                    <li>
                    <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold bg-primary text-white" onClick={()=>{setReadOnly(!readOnly)
                      getConnectorPayout()}
                    }>
                      <i className="pi pi-pencil"></i>
                      <span>Edit</span>
                    </button>
                  </li>
                </ul>
                </>
                  
                )}
                { cid && !readOnly&& (
                  <>
                  <ul className="flex space-x-[18px]">
                  <li>
                    <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold bg-primary text-white" onClick={()=>addBlankRow()}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>Add</span>
                    </button>
                  </li>
                </ul>
                <ul className="flex space-x-[18px]">
                <li>
                  <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold bg-primary text-white" onClick={()=>deletePayout()}>
                  <i className="pi pi-minus"></i>
                  <span>Delete</span>
                  </button>
                </li>
              </ul></>
                  
                )}
              </div>
              { cid && !readOnly &&(
                <ul className="flex space-x-[18px]">
                  <li>
                    <button className="flex items-center space-x-[6px] py-2 px-6 rounded-[18px] border border-light-gray text-xs font-semibold bg-primary text-white" onClick={(e) => op.current.toggle(e)} 
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>Pre-Fill</span>
                      <OverlayPanel ref={op}>
                <option style={{ margin: '10px 0',cursor: 'default' }} value='bronze' onClick={() => {
                      getResetData('bronze')
                    }}>Bronze</option>
                    <option style={{ margin: '10px 0',cursor: 'default' }} value='silver' onClick={() => {
                      getResetData('silver')
                    }}>Silver</option>
                    <option style={{ margin: '10px 0',cursor: 'default' }} value='gold' onClick={() => {
                      getResetData('gold')
                    }}>Gold</option>
                    </OverlayPanel>
                      {/* <OverlayPanel ref={op}>
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    <li style={{ margin: '10px 0' }} onClick={() => {
                      setSelectedOption('bronze')
                      getResetData('bronze')
                    }}>Bronze</li>
                    <li style={{ margin: '10px 0' }} onClick={() => {
                      setSelectedOption('silver')
                      getResetData('silver')
                    }}>Silver</li>
                    <li style={{ margin: '10px 0' }} onClick={() => {
                      setSelectedOption('gold')
                      getResetData('gold')
                    }}>Gold</li>
                </ul>
            </OverlayPanel> */}
                    </button>
                  </li>
                </ul>
              )}
              {/* END::DASHBOARD HEADER */}
            </div>
            {/* MODEL-----Start---------------- */}

            {/* Start::Terms and Condition Add Modal */}
            <div className={`tw-modal ${addTnCOpen ? "flex" : "hidden"}`}>
              <div
                className="tw-modal-content"
                style={{ maxHeight: "500px", overflowY: "hidden" }}
              >
                {/* Header */}
                <div className="tw-modal-title">
                  <div>Terms and Condition</div>
                  <button onClick={() => setAddTnCOpen(false)}>
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>

                <div
                  className="tw-modal-content-scroll"
                  style={{ maxHeight: "calc(500px - 60px)", overflowY: "auto" }}
                >
                  <div className="tw-modal-body py-6">
                    <button
                      className="p-button p-button-primary"
                      onClick={() => addNewTermAndCondition()}
                    >
                      <i className="pi pi-plus-circle mr-2"></i> Add
                    </button>
                    {/* Render the list of terms and conditions */}
                    <ol className="list-decimal ml-5 text-xs text-[#808080] leading-[26px] space-y-4">
                      {termsConditionType === "main" &&
                      baseTerms &&
                      baseTerms.length > 0
                        ? baseTerms.map((term, index) => (
                            <li
                              key={index}
                              className="flex items-center justify-between"
                            >
                              <span>
                                {index + 1}. {term.term_and_condition}
                              </span>
                              {/* Edit and Delete buttons */}
                              <div>
                                <button
                                  className="edit-button mr-4"
                                  onClick={() =>
                                    editTermsClicked(
                                      term._id,
                                      termsConditionType
                                    )
                                  }
                                >
                                  <img
                                    src="../assets/edit-pencil.svg"
                                    alt="Edit"
                                  />
                                </button>
                                <button
                                  className="delete-button"
                                  onClick={() => handleDelete(term._id)}
                                >
                                  <img
                                    src="../assets/trash-red.svg"
                                    alt="Delete"
                                  />
                                </button>
                              </div>
                            </li>
                          ))
                        : rowTerms?.map((term, index) => (
                            <li
                              key={index}
                              className="flex items-center justify-between"
                            >
                              <span>
                                {index + 1}. {term.term_and_condition}
                              </span>
                              {/* Edit and Delete buttons */}
                              <div>
                                <button
                                  className="edit-button mr-4"
                                  onClick={() =>
                                    editTermsClicked(
                                      term._id,
                                      termsConditionType
                                    )
                                  }
                                >
                                  <img
                                    src="assets/edit-pencil.svg"
                                    alt="Edit"
                                  />
                                </button>
                                <button
                                  className="delete-button"
                                  onClick={() => handleDelete(term._id)}
                                >
                                  <img
                                    src="assets/trash-red.svg"
                                    alt="Delete"
                                  />
                                </button>
                              </div>
                            </li>
                          ))}
                    </ol>
                  </div>

                  {/* Footer */}
                  <div className="tw-modal-footer">
                    <button
                      className="tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary"
                      onClick={() => setAddTnCOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* End::Terms and Condition Add Modal */}

            {/* Start::Edit Terms and Condition Modal */}
            <div className={`tw-modal ${editTnCModal ? "flex" : "hidden"}`}>
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>Edit Terms and Condition</div>
                  <button onClick={() => setEditTnCModal(false)}>
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>

                <div className="tw-modal-body py-6">
                  <ol className="list-decimal ml-4 text-xs text-[#808080] leading-[26px] space-y-4">
                    <li className="flex items-center gap-4">
                      <input
                        className={`w-full py-4 px-[22px] border text-sm rounded-lg ${
                          termAndConditionData.termAndCondition_error
                            ? "border-red-500"
                            : "border-[#DEDEDE]"
                        }`}
                        type="text"
                        name="termscondition"
                        placeholder="Add Terms and Condition"
                        value={termAndConditionData.termAndCondition}
                        onChange={(e) => {
                          setTermAndCondition({
                            ...termAndConditionData,
                            termAndCondition: e.target.value,
                            termAndCondition_error: null,
                          });
                        }}
                      />
                    </li>
                    <p className="text-red-500 text-sm">
                      {termAndConditionData.termAndCondition_error}
                    </p>
                  </ol>
                </div>
                <div className="tw-modal-footer">
                  <button
                    className="tw-modal-footer-button bg-secondary text-white"
                    onClick={() => UpdateTermsAndConditions()}
                  >
                    Update
                  </button>
                  <button
                    className="tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary"
                    onClick={() => setEditTnCModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* End::Edit Terms and Condition Modal */}

            {/* Start::Add Terms and Condition Modal */}
            <div className={`tw-modal ${addTnCModal ? "flex" : "hidden"}`}>
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>Add Terms and Condition</div>
                  <button onClick={() => setAddTnCModal(false)}>
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>

                <div className="tw-modal-body py-6">
                  <ol className="list-decimal text-xs text-[#808080] leading-[26px] space-y-4">
                    <li className="flex items-center gap-4">
                      <input
                        className={`w-full py-4 px-[22px] border text-sm rounded-lg ${
                          termAndConditionData.termAndCondition_error
                            ? "border-red-500"
                            : "border-[#DEDEDE]"
                        }`}
                        type="text"
                        placeholder="Add Terms and Condition"
                        value={termAndConditionData.termAndCondition}
                        onChange={(e) => {
                          setTermAndCondition({
                            ...termAndConditionData,
                            termAndCondition: e.target.value,
                            termAndCondition_error: null,
                          });
                        }}
                      />
                    </li>
                    <p className="text-red-500 text-sm">
                      {termAndConditionData.termAndCondition_error}
                    </p>
                  </ol>
                </div>
                <div className="tw-modal-footer">
                  <button
                    className="tw-modal-footer-button bg-secondary text-white"
                    onClick={() => AddTermsAndConditions()}
                  >
                    Add
                  </button>
                  <button
                    className="tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary"
                    onClick={() => setAddTnCModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* End::Edit Terms and Condition Modal */}

            {/* Start:: Add Payout Grid Modal */}
            <div className={`tw-modal ${addPayloadModel ? "flex" : "hidden"}`}>
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>Add Payout</div>
                  <button onClick={() => setAddPayloadModel(false)}>
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>

                <div className="tw-modal-body py-6">
                  <div className="d-flex">
                    <div className="space-y-1">
                      <label
                        htmlFor="normal_payout"
                        className={`input-label ${
                          formData.normal_payout_error ? "text-red-500" : ""
                        }`}
                      >
                        Normal Payout<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                        id="normal_payout"
                        className={`input-wrap ${
                          formData.normal_payout_error ? "border-red-500" : ""
                        }`}
                      >
                        <input
                          type="text"
                          name="normal_payout"
                          value={formData.normal_payout}
                          onInput={handleInput}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              normal_payout: e.target.value,
                              normal_payout_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Normal Payout"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData.normal_payout_error}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tw-modal-body py-6">
                  <div className="d-flex">
                    <div className="space-y-1">
                      <label
                        htmlFor="capping"
                        className={`input-label ${
                          formData.capping_error ? "text-red-500" : ""
                        }`}
                      >
                        Capping<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                        id="capping"
                        className={`input-wrap ${
                          formData.capping_error ? "border-red-500" : ""
                        }`}
                      >
                        <input
                          type="text"
                          name="capping"
                          value={formData.capping}
                          onInput={handleInput}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              capping: e.target.value,
                              capping_error: null,
                            });
                          }}
                          className="w-full text-sm"
                          placeholder="Capping"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData.capping_error}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tw-modal-body py-6">
                  <div className="d-flex">
                    <div className="space-y-1">
                      <label
                        htmlFor="logincodelistid"
                        className={`input-label ${
                          formData.logincodelistid_error ? "text-red-500" : ""
                        }`}
                      >
                        Code list<span style={{color:'red'}}>*</span>
                      </label>
                      <div
                        id="logincodelistid"
                        className={`${
                          formData.logincodelistid_error ? "border-red-500" : ""
                        }`}
                      >
                        <Select
                          options={codeDataOptions}
                          value={codeDataOptions.find(
                            (opt: any) => opt.value === formData.logincodelistid
                          )}
                          onChange={handleProductChange}
                          className="w-full text-sm py-2"
                          placeholder="Type to search..."
                          styles={customStyles(formData.logincodelistid_error)}
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData.logincodelistid_error}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tw-modal-footer">
                  <button
                    className="tw-modal-footer-button bg-secondary text-white"
                    onClick={() => handleSave()}
                  >
                    {"Add"}
                  </button>
                  <button
                    className="tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary"
                    onClick={() => setAddPayloadModel(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* End:: Add Payout Grid Modal */}

            {/* Start::Edit Payout Grid Modal */}
            <div className={`tw-modal ${editOpen ? "flex" : "hidden"}`}>
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>Edit Payout Grid</div>
                  <button onClick={() => setEditOpen(false)}>
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>
                <div className="tw-modal-body py-5">
                  <div className="col-span-2 space-y-6">
                    <div className="space-y-2">
                      <div className="w-full flex gap-5">
                        <div className="w-full space-y-2">
                          <label
                            htmlFor="normal_payout"
                            className={`input-label ${
                              normalSlot.normal_payout_error
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            Normal Payout<span style={{color:'red'}}>*</span>
                          </label>
                          <div
                            id="normal_payout"
                            className={`input-wrap ${
                              normalSlot.normal_payout_error
                                ? "border-red-500"
                                : ""
                            }`}
                          >
                            <input
                              type="number"
                              name="normal_payout"
                              value={normalSlot.normal}
                              onChange={(e: any) => {
                                setNormalSlot({
                                  ...normalSlot,
                                  normal: e.target.value,
                                  normal_payout_error: null,
                                });
                              }}
                              className="w-full text-sm"
                              placeholder="2.55"
                            />
                          </div>
                          <p className="text-red-500 text-sm">
                            {normalSlot.normal_payout_error}
                          </p>
                        </div>
                        {!userid && (
                          <>
                            <div className="w-full space-y-2">
                              <label
                                htmlFor="capping"
                                className={`input-label ${
                                  normalSlot.capping_error ? "text-red-500" : ""
                                }`}
                              >
                                capping<span style={{color:'red'}}>*</span>
                              </label>
                              <div
                                id="capping"
                                className={`input-wrap ${
                                  normalSlot.capping_error
                                    ? "border-red-500"
                                    : ""
                                }`}
                              >
                                <input
                                  type="number"
                                  name="capping"
                                  value={normalSlot.capping}
                                  onChange={(e: any) => {
                                    setNormalSlot({
                                      ...normalSlot,
                                      capping: e.target.value,
                                      capping_error: null,
                                    });
                                  }}
                                  className="w-full text-sm"
                                  placeholder="2.55"
                                />
                              </div>
                              <p className="text-red-500 text-sm">
                                {normalSlot.capping_error}
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tw-modal-footer">
                  <button
                    className="tw-modal-footer-button bg-secondary text-white"
                    onClick={editPayout}
                  >
                    Save
                  </button>
                  <button
                    className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                    onClick={() => setEditOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            {/* End::Edit Payout Grid Modal */}

            {/* Start::View history */}
            <div className={`tw-modal ${historyModel ? "flex" : "hidden"}`}>
              <div className="tw-modal-content">
                <div className="tw-modal-title">
                  <div>History</div>
                  <button onClick={() => setHistoryModel(false)}>
                    <img src="../assets/close-outline.svg" alt="" />
                  </button>
                </div>

                <div className="tw-modal-body py-5">
                  <table className="w-full">
                    <thead className="text-sm text-light-gray bg-gray-200">
                      <tr>
                        <th className="py-3 px-5">Normal Payout</th>
                        <th className="py-3 px-5">Advance Payout</th>
                        <th className="py-3 px-5">Capping Payout</th>
                        <th className="py-3 px-5">Start Date</th>
                        <th className="py-3 px-5">End Date</th>
                      </tr>
                    </thead>
                    <tbody className="bg-gray-100 text-sm text-light-gray">
                      {historyData?.map((details: any) => (
                        <tr className="border-b">
                          <td className="py-3 px-5 text-center">
                            {details.normal_payout}
                          </td>
                          <td className="py-3 px-5 text-center">
                            {details.advance_payout}
                          </td>
                          <td className="py-3 px-5 text-center">
                            {details.capping_payout}
                          </td>
                          <td className="py-3 px-5 text-center">
                            {details.start_date}
                          </td>
                          <td className="py-3 px-5 text-center">
                            {details.end_date}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* End::View history */}

            {/* MODEL-----End---------------- */}

            {/* END::DASHBOARD HEADER */}
            {/* Custom popup */}
            <Tooltip
              target={tooltipTarget}
              mouseTrack
              mouseTrackLeft={5}
              mouseTrackTop={5}
              position="top"
              onHide={() => setIsTooltipOpen(false)}
            >
              {tooltipContent}
            </Tooltip>
            {!cid && !permission[PERMISSION_TYPE.CONNECTOR] && 
            <DataTable
              value={payoutGridDetail}
              lazy
              dataKey="id"
              showGridlines
              paginator
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              header={header}
              emptyMessage="No records found."
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
            >
              <Column field="SRNO" header="SR.NO." />
              <Column
                field="bank_name"
                header="Bank Name"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={bankNameRowFilterTemplate}
              />
              <Column
                field="product_name"
                header="Product"
                showFilterMatchModes={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={productRowFilterTemplate}
              />
              <Column
                field="Normal"
                header="Normal Payout Slab"
                body={(rowData) => (
                  <div>
                    {rowData.Normal}
                    <i
                      className="pi pi-info-circle icon-tooltip"
                      onMouseEnter={(e) => handleIconMouseEnter(e, rowData)}
                      onMouseLeave={handleIconMouseLeave}
                      style={{ marginLeft: "0.5rem" }}
                    ></i>
                  </div>
                )}
              />
              <Column
                field="Advance"
                header="Advance Payout Slab"
                body={(rowData) => (
                  <div>
                    {rowData.Advance}
                    <i
                      className="pi pi-info-circle"
                      onMouseEnter={(e) => handleIconMouseEnter(e, rowData)}
                      onMouseLeave={handleIconMouseLeave}
                      style={{ marginLeft: "0.5rem" }}
                    ></i>
                  </div>
                )}
              />
              {(permission[PERMISSION_TYPE.ADMIN] || permission[PERMISSION_TYPE.STAFF_OPS] || user.role == 'staff_sales') ? (
                <Column
                  field="Action"
                  header="Action"
                  body={actionBodyTemplate}
                />
              ) : null}
            </DataTable>}
            { (cid || permission[PERMISSION_TYPE.CONNECTOR] )&&  
            <div>
              <DataTable
              value={connectorformData}
              lazy ={cid ? false : true}
              readOnly={readOnly}
              dataKey="id"
              showGridlines
              sortField={cid ? 'bank_name' : ''}
              sortOrder={1}
              paginator={pagination}
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              header={header}
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
              editMode="cell"
              selection={selectedUsers}
              onSelectionChange={(e) => {
                setSelectedUsers(e.value)
              }}
              emptyMessage="No records found."
              selectionMode={"checkbox"} 
           >
              { !readOnly && <Column selectionMode="multiple" frozen />}
              <Column body={renderSRNO} header="SR.NO." />
              <Column
                field="bank_name"
                header="Bank Name"
                sortable={cid ? true : false}
                // editor={(Options )=> bankTemplate(Options)}   
                // onCellEditComplete={(e)=> {
                //   e.rowData[e.field] = e.newValue;
                //   setConnectorFormData([...connectorformData])
                // }
                // }             
              />
              <Column
                field="product_name"
                header="Product"
                sortable={cid ? true : false}
                // editor={(Options )=> productTemplate(Options)}   
                // onCellEditComplete={(e)=> {
                //   let product= bankData[e.rowData.bank_name].find((item)=>
                //     item.id === e.newValue
                //     )
                //     e.rowData['logincodelist']=e.newValue
                //     e.rowData[e.field]=product.product;
                //   // e.rowData['logincodelist'] = e.newRowData.product;
                //   setConnectorFormData([...connectorformData])

                // }}             
              />
              <Column
                field="Normal"
                header="Normal Payout Slab"
                sortable={cid ? true : false}
                editor={readOnly === true ? '' : (options)=> textEditor(options)}
                onCellEditComplete={(e)=> {  
                  e.rowData[e.field]=e.newValue
                  e.rowData['Advance']=(e.newValue-0.25).toString()
                  setConnectorFormData([...connectorformData])
                }}
                body={cid ? "" : (rowData) => (
                  <div>
                    {rowData.Normal}
                    <i
                      className="pi pi-info-circle icon-tooltip"
                      onMouseEnter={(e) => handleIconMouseEnter(e, rowData)}
                      onMouseLeave={handleIconMouseLeave}
                      style={{ marginLeft: "0.5rem" }}
                    ></i>
                  </div>
                )}
              />
              <Column
                field="Advance"
                header="Advance Payout Slab"
                sortable={cid ? true : false}
                body={cid ? "" : (rowData) => (
                  <div>
                  {rowData.Advance}
                  <i
                    className="pi pi-info-circle"
                    onMouseEnter={(e) => handleIconMouseEnter(e, rowData)}
                    onMouseLeave={handleIconMouseLeave}
                    style={{ marginLeft: "0.5rem" }}
                  ></i>
                </div>
                )}
              />
             
            </DataTable>
            { !readOnly && <div className="flex flex-wrap gap-5 items-end justify-end p-8">
            <div className="md:flex w-full lg:w-[unset] items-end justify-end md:space-x-9 mi-auto">
            <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                            <li>
                                <button className={`py-2 px-7 text-xs font-medium`} onClick={() => { saveEditPayout()

                                }}>Update </button>
                            </li>
                        </ul>
                        <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">    
                            <li>
                                <button className={`py-2 px-7 text-xs font-medium`} onClick={() => { 
                                  setReadOnly(!readOnly)
                                  getConnectorPayout()
                                }}>Cancel</button>
                            </li>                        

                        </ul>
                        </div>
                        </div>}
                        </div>
            }

              <div className={`tw-modal ${connectorpayout ? "flex" : "hidden"}`}>
              <div
                className="tw-modal-content"
              >
                {/* Header */}
                <div className="tw-modal-title">
                  <div>Add PayoutGrid</div>
                  <button onClick={() => setConnectorPayout(false)}>
                    <img src="assets/close-outline.svg" alt="" />
                  </button>
                </div>
                <div>

                <div className="tw-modal-body py-5">
                  <div className="d-flex">
                    <div className="space-y-2">
                          <label
                            htmlFor="normal_payout"
                            className={`input-label ${
                              normalSlot.normal_payout_error
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                           Bank Name
                          </label>
                          <div
                      id="financer_name"
                      className={`input-wrap ${
                        formData.financer_name_error ? "border-red-500" : ""
                      }`}
                    >
                      <img src="assets/bank.svg" alt="" />
                      <select
                        name="financer_name"
                        value={addFormData.bank_name}
                        onChange={(e) =>  {    
                          setAddFormData({...addFormData, bank_name:e.target.value,product_name:''})  
                        }}
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="" selected>
                          Select Bank/NBFC Name
                        </option>
                        {Object.keys(editbankData)?.map((bank: any, id: any) => (
                          <option value={bank}>{bank}</option>
                        ))}
                      </select>
                    </div>
                            {/* <Select
                                                options={bankNameOptions}
                                                value={bankNameOptions.find((e)=> e.value === connectorformData.bank_name)}
                                                onChange={(e )=>{ 
                                                  console.log('callleddddddddddd',e)
                                                  setConnectorFormData({...connectorformData, bank_name:e.value})               
                                                }}
                                                className="w-full text-sm py-2"
                                                placeholder="Type to search..."
                                                // styles={customStyles(formData.department_error)}
                                            /> */}
                          <p className="text-red-500 text-sm">
                          </p>
                        </div>
                        <div className="w-full space-y-2">
                          <label
                            htmlFor="normal_payout"
                            className={`input-label ${
                              normalSlot.normal_payout_error
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                           Product
                          </label>
                          <div
                      id="product"
                      className={`input-wrap ${
                        formData.product_error ? "border-red-500" : ""
                      }`}
                    >
                          <img src="assets/product.svg" alt="" />
                      <select
                        name="product"
                        value={addFormData?.LoginCode}
                        onChange={(e) =>{
                          let product= editbankData[addFormData.bank_name].find((item)=>
                            item.id === e.target.value
                            )
                          setAddFormData({
                            ...addFormData,
                            LoginCode: e.target.value,
                            product_name:product.product
                          })
                          }
                        }
                        className="w-full text-sm text-[#808080]"
                      >
                        <option value="" selected>
                          Select Product
                        </option>
                        {editbankData?.[addFormData.bank_name]?.map(
                          (item: any) => (
                            <option value={item.id}>{item.product}</option>
                          )
                        )}
                      </select>
                    </div>
                            {/* <Select
                                                options={productOptions}
                                                value={productOptions.find((e)=> e.value === connectorformData.product)}
                                                onChange={(e )=>{ 
                                                  console.log('callleddddddddddd',e)
                                                  setConnectorFormData({...connectorformData, product:e.value})               
                                                }}
                                                className="w-full text-sm py-2"
                                                placeholder="Type to search..."
                                                // styles={customStyles(formData.department_error)}
                                            /> */}
                          <p className="text-red-500 text-sm">
                          </p>
                        </div>
                        <div className="w-full space-y-2">
                          <label
                            htmlFor="normal_payout"
                            className={`input-label ${
                              normalSlot.normal_payout_error
                                ? "text-red-500"
                                : ""
                            }`}
                          >
                            Normal Payout
                          </label>
                          <div
                            id="normal_payout"
                            className={`input-wrap ${
                              normalSlot.normal_payout_error
                                ? "border-red-500"
                                : ""
                            }`}
                          >
                            <input
                              type="number"
                              name="normal_payout"
                              value={addFormData?.Normal}
                              onChange={(e: any) => {
                                setAddFormData({
                                  ...addFormData,
                                  Normal: e.target.value,
                                  Advance:(e.target.value-0.25)
                                });
                              }}
                              className="w-full text-sm"
                              placeholder="2.55"
                            />
                          </div>
                          <p className="text-red-500 text-sm">
                          </p>
                        </div>
                  </div>
                </div>

                  {/* Footer */}
                  <div className="tw-modal-footer">
                  <button
                    className="tw-modal-footer-button bg-secondary text-white"
                    onClick={() =>savePayout()
                    }
                  >
                    {"Add"}
                  </button>
                    <button
                      className="tw-modal-footer-button bg bg-secondary bg-opacity-10 text-secondary"
                      onClick={() => {setConnectorPayout(false)
                        setAddFormData(data)
                        // getConnectorPayout()
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </main> */}

          <Toast ref={toastRef} />
          <ConfirmDialog
            // group="declarative"
            visible={deleteConfirmVisible}
            onHide={() => setDeleteConfirmVisible(false)}
            message="Are you sure you want to delete?"
            header="Confirmation"
            icon="pi pi-exclamation-triangle"
            accept={accept}
            reject={reject}
            style={{ width: "50vw" }}
            breakpoints={{ "1100px": "75vw", "960px": "100vw" }}
          />
          {/* END::MAIN CONTENT */}
        </>
      )}
    </div>
  );
}

export default PayoutGrid;

import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import TableComponent from "../../components/Tables";
import { TableCell, TableRow } from "@mui/material";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { STATE } from "../../constants";
import { useNavigate } from "react-router";
import { Button } from "primereact/button";

const BankDisbursementDataModal = () => {
  const [loader, setLoader] = useState(true);

  let { id } = useParams();
  const [viewData, setViewData] = useState<any>([]);
  const [viewDataState, setViewDataState] = useState(STATE.DEFAULT_STATE);
  const [viewrowData, setRowData] = useState<any>();

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };
  let viewDataColumns = [
    { id: "customer_name", label: "Customer Name" },
    { id: "lan_no", label: "Lan No" },
    { id: "loan_amount", label: "Loan Amount" },
    { id: "payout", label: "Payout" },
    { id: "subvention", label: "Subvention" },
  ];
  const getMonthString = (month: string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months[parseInt(month, 10) - 1]; // Subtract 1 because array is zero-based
  };
  useEffect(() => {
    Promise.all([setViewModalData()])
      .then(() => {
        setTimeout(() => {
          setLoader(false);
        }, 250);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, []);
  const setViewModalData = () => {
    ApiGet(`bankdisbursement/get_bankdisbursement/${id}`).then((res: any) => {
      if (res?.data?.result && res?.data?.result.length > 0) {
        setViewData(res?.data?.result);
        setRowData(res?.data?.result[0]);
      }
    });
  };

  return (
    <div className="overflow-x-hidden p-6">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          <div className="flex flex-wrap gap-5 items-center justify-between mb-5">
            <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
            <button  className="mt-2" onClick={goBack}> <i className="pi pi-arrow-circle-left" style={{ fontSize: '2.0rem' }}></i> </button>
              <h1 className="text-[22px] text-light-gray font-semibold">
                Bank Disbursement Detail
              </h1>
            </div>
            {/* Start:: view Modal */}
            
          </div>
          <div>
          <div className="flex m-4">
              <div>
                <label className="font-bold">Bank: </label>
                <span>{viewrowData?.bank_name}</span>
              </div>
              <div className="ml-10">
                <label className="font-bold">Product: </label>
                <span>{viewrowData?.product}</span>
              </div>
              <div className="ml-10">
                <label className="font-bold">Year: </label>
                <span>{viewrowData?.year}</span>
              </div>
              <div  className="ml-10">
                <label className="font-bold">Month: </label>
                <span>{getMonthString(viewrowData?.month)}</span>
              </div>
            </div>
            <TableComponent
              rows={viewData}
              columns={viewDataColumns}
              state={viewDataState}
              renderColumn={(column: any) => {
                return (
                  <TableCell
                    align="center"
                    className="!bg-gray-200"
                    key={column.id}
                  >
                    {column.label}
                  </TableCell>
                );
              }}
              renderRow={(row: any, index: number) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                    <TableCell align="center" component="th" scope="row">
                      {row.customer_name}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.lan_no}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.loan_amount}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.payout}
                    </TableCell>
                    <TableCell align="center" component="th" scope="row">
                      {row.subvention}
                    </TableCell>
                  </TableRow>
                );
              }}
            />

            {/* End:: view Modal */}
          </div>
        </>
      )}
    </div>
  );
};

export default BankDisbursementDataModal;
